.head_y{
    height: 40vh;
    background-color: #5D515C;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.TitreDivCgv{
    text-align: center;
    font-family: Larken;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 61.935px;
    text-transform: uppercase;
    background: linear-gradient(to bottom, rgba(192, 160, 163, 1), rgba(232, 186, 139, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 16px;

}

.cgv-title {
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;
    font-family: Larken;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    background: linear-gradient(to bottom, rgba(192, 160, 163, 1), rgba(232, 186, 139, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }

  .cgv-div{
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin: 32px 0;
  }

  .Retour{
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }