
.team-card {
    width: 440px;
    background: linear-gradient(180deg, #BF9FA2 0%, #E7B98A 100%);
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    margin: 0px 48px;
  }

.TeamCardText{
    margin: 0px 48px;
}
  
  .team-card img {
    width :341px;
    object-fit: cover;

  }
  
  .name {
    color: #1A1A1A;
    font-family: Larken;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    text-transform: uppercase;
    
  }
  
  .role {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-transform: uppercase;
    background: linear-gradient(to bottom, rgba(192, 160, 163, 1), rgba(232, 186, 139, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .team-card .team-card-img {
    transition: transform 0.5s ease-in-out;
  }
  
  .team-card .team-card-img:hover {
    transform: scale(0.97);
  }

  /* When the screen size is 537px or less, reduce the size of the card and the image */
@media screen and (max-width: 1076px) {
    .team-card {
        width: 330px; 
        margin: 0px 24px; 

    }
    
    .team-card img {
        width: 255.75px; 
    }

    .TeamCardText {
        margin: 0px 24px;
        margin-bottom: 16px;
    }
}